import React from 'react'

import Icon from '../data/icon.png';
const Entete = () => {
    return (
        <div className="flex justify-between gap-3">
            <div className="flex flex-col gap-3">
                <h1 className='font-bold'>Écoles privées SchoolApp</h1>
                <h2 className='text-sm font-bold'>Jardin-Primaire-Collège-Secondaire</h2>
                <h2>Tel: 44957777 - 34988888</h2>
            </div>
            <img className="mx-auto h-28 w-auto" src={Icon} alt="Your Company" />
            <div className="flex flex-col text-right gap-3">
                <h1 className='font-bold'> مدارس سكول آب الحرة </h1>
                <h1 className='font-bold'>روضة ابتدائي إعدادي ثانوي</h1>
                <h2> 44957777 - 34988888 : الهاتف</h2>
            </div>
        </div>
    )
}

export default Entete