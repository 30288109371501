import React, { useEffect, useState } from 'react'
import { Button, Header, Input } from '../../components'
import { useNavigate, useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import Loading from '../../components/Loading';
import Alert from '../../components/Alert';
import Select from 'react-select';
import { toast } from 'react-toastify';
import axiosClient from '../../axios-client';
import { BiSave } from 'react-icons/bi';
import { GiCancel } from "react-icons/gi";
import { PiStudentBold } from 'react-icons/pi';

const EditInscription = () => {
    const { id } = useParams();
    const [items, setItems] = useState([]);
    const [inputValue, setValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [errors, setErrors] = useState(null);
    const { token, setToken } = useStateContext();
    const [inscription, setInscription] = useState({
        id: '',
        eleve: '',
        eleveAr: '',
        montant: '',
        reduction: 1,
        telParent: '',
        rim: '',
        nni: '',
        genre: '',
        dateNaissance: '',
        commentaire: '',
        classe: {},
        whatsapp: '',
        autreTel: '',
        telegram: '',

    });


    const navigate = useNavigate();
    // handle input change event
    const handleInputChange = value => {
        setValue(value);
    };

    // handle selection
    const handleChange = value => {
        setSelectedValue(value);
        setInscription({ ...inscription, classe: value })

    }

    const onClose = () => {
        navigate('/inscriptions');
    }

    useEffect(() => {
        getClasses();
        getInscriptionInfo();
    }, [])
    const getInscriptionInfo = () => {
        setLoadingInfo(true);
        axiosClient.get('/getInscription/' + id)
            .then(response => {
                setInscription(response.data.inscription);
                setSelectedValue(response.data.inscription.classe);
                setLoadingInfo(false);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    const getClasses = () => {
        axiosClient.get('/classes')
            .then((data) => {
                setItems(data.data.classes);
            })
    }

    const onSubmit = () => {
        setLoading(true);
        axiosClient.post('/inscription/update', inscription)
            .then((response) => {
                setLoading(true);
                navigate('/inscriptions')
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }


    return (
        <>
            {loadingInfo ? <Loading /> :
                <div>
                    <Header icon={<PiStudentBold />} category='Page' title='Modifier Inscription' />
                    {errors &&
                        Object.keys(errors).map(key => (
                            <Alert key={key} message={errors[key][0]} />
                        ))
                    }
                    <form>

                        <div className="flex ">
                            <Input label="Nom" type="text" value={inscription.eleve} onChange={ev => setInscription({ ...inscription, eleve: ev.target.value })} required="required" />
                            <Input label="NomAr" type="text" value={inscription.eleveAr} onChange={ev => setInscription({ ...inscription, eleveAr: ev.target.value })} required="required" />
                            <Input label="Tel Parent" type="text" value={inscription.telParent} onChange={ev => setInscription({ ...inscription, telParent: ev.target.value })} required="required" />
                            <Input label="Mensualitée" type="number" value={inscription.montant} onChange={ev => setInscription({ ...inscription, montant: ev.target.value })} required="required" />
                        </div>
                        <div className="flex mt-2">
                            <Input label="Whatsapp" type="text" value={inscription.whatsapp} onChange={ev => setInscription({ ...inscription, whatsapp: ev.target.value })} />
                            <Input label="Autre Tel" type="text" value={inscription.autreTel} onChange={ev => setInscription({ ...inscription, autreTel: ev.target.value })} />
                            <Input label="TelegramId" type="text" value={inscription.telegram} onChange={ev => setInscription({ ...inscription, telegram: ev.target.value })} />

                        </div>
                        <div className="flex mt-2">
                            <Input label="NNI" type="text" value={inscription.nni} onChange={ev => setInscription({ ...inscription, nni: ev.target.value })} />
                            <Input label="RIM" type="text" value={inscription.rim} onChange={ev => setInscription({ ...inscription, rim: ev.target.value })} />
                            <Input label="Genre" type="text" value={inscription.genre} onChange={ev => setInscription({ ...inscription, genre: ev.target.value })} />

                        </div>

                        <div className="flex mt-2">
                            <div className="flex-col w-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">Classe <span className='text-red-500'> *</span></label>
                                <Select className='mt-2 outline-1 ' placeholder="Classe" cacheOptions defaultOptions value={selectedValue} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={items} onInputChange={handleInputChange} onChange={handleChange} />
                            </div>
                            <Input label="Reduction" type="number" value={inscription.reduction} onChange={ev => setInscription({ ...inscription, reduction: ev.target.value })} required="required" />
                            <Input label="Date de naissance" type="date" value={inscription.dateNaissance} onChange={ev => setInscription({ ...inscription, dateNaissance: ev.target.value })} />
                        </div>

                        <Input label="Commentaire" type="text" value={inscription.commentaire} onChange={ev => setInscription({ ...inscription, commentaire: ev.target.value })} />
                        {/* <Select className='mt-4 outline-1 ' placeholder="Selectionner une agence" cacheOptions defaultOptions value={selectedValue} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={items} onInputChange={handleInputChange} onChange={handleChange} /> */}
                        <div className="flex justify-end items-end mt-4">
                            {loading ? <Loading /> :
                                <>
                                    <div className='mr-2'>
                                        <Button color="bg-red-500" onClick={() => onClose()} icon={<GiCancel />} size="20" text="Annuler" textColor="text-white" />
                                    </div>
                                    <Button color="bg-blue-500" onClick={() => onSubmit()} icon={<BiSave />} size="20" text="Enregistrer" textColor="text-white" />
                                </>
                            }
                        </div>
                    </form>
                </div>

            }
        </>
    )
}

export default EditInscription