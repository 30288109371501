import React, { useEffect, useState } from 'react'
import { Button, Header } from '../../components'
import { BsFillBuildingFill, BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Loading from '../../components/Loading';
import { useStateContext } from '../../contexts/ContextProvider';
import { AiTwotoneEdit } from 'react-icons/ai';
import ActionButton from '../../components/ActionButton';
import AddClasse from './AddClasse';
import EditClasse from './EditClasse';

const Classes = () => {
  const navigate = useNavigate();
  const [classes, setClasses] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setToken, user } = useStateContext();
  const [showAdd, setShowAdd] = useState(false);

  const [showEdit, setShowEdit] = useState(false);

  const [selcetedClasse, setSelectedClasse] = useState(null);
  const getClasses = () => {
    axiosClient.get('/classes')
      .then((data) => {
        setClasses(data.data.classes);
        setLoading(false);

      }).catch(err => {
        const response = err.response;
        if (err.response.status === 401) {
          setToken(null);
        } else {
          if (response.status === 401) {
            setToken(null);
          }
          else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }
        }

      })
  }

  const closeAdd = () => {
    setShowAdd(false);
    getClasses();
  }

  const closeEdit = () => {
    setShowEdit(false);
    setSelectedClasse(null);
    getClasses();
  }

  useEffect(() => {
    getClasses();
  }, []);
  return (
    <>
      <Header icon={<BsFillBuildingFill />} category='Page' title='Classes' />
      <div className='flex-col '>
        <div className='items-end'>
          {user.role && user.role.name === 'admin' ?
            <Button color="bg-blue-500" onClick={() => {
              setShowAdd(true);
            }} icon={<BsPlusCircleFill />} size="20" text="Ajouter" textColor="text-white" />
            :
            <></>
          }
        </div>
        <div className='mt-3 w-full overflow-x-auto'>
          {loading ? <Loading /> :
            (
              <table className='table-auto'>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Libelle</th>
                    <th>Prix</th>
                    <th>Capacite</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {classes && classes.map(o => (
                    <tr key={o.id}>
                      <td>{o.code}</td>
                      <td>{o.libelle}</td>
                      <td>{o.prix}</td>
                      <td>{o.capacite}</td>
                      <td>
                        <div className="flex justify-evenly">
                          <ActionButton onClick={() => {
                            setSelectedClasse(o);
                            setShowEdit(true);
                          }} color="bg-green-500" icon={<AiTwotoneEdit />} textColor="text-white" title="Modifier" />
                          <ActionButton color="bg-red-500" icon={<BsTrash />} textColor="text-white" title="Supprimer" />
                        </div>
                      </td>
                    </tr>
                  ))

                  }
                </tbody>
              </table>
            )

          }
        </div>
      </div>
      {
        showAdd ? <AddClasse onClose={closeAdd} /> : <></>
      }
      {
        showEdit ? <EditClasse selectedClasse={selcetedClasse} onClose={closeEdit}/> : <></>
      }
    </>
  )
}

export default Classes