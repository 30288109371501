import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { GiCancel } from 'react-icons/gi';
import { FaCalendarAlt, FaExclamation } from 'react-icons/fa';

const EleveObservation = ({ student, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [observations, setObservations] = useState([]);
    const [observationsDevoir, setObservationsDevoir] = useState([]);

    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    const navigate = useNavigate();

    const getObservations = () => {
        setLoading(true);
        axiosClient.get('/eleveObservations/' + student.id)
            .then((data) => {
                setObservations(data.data.observations);
                setLoading(false);
                setErrors([]);
            }).catch(err => {
                const response = err.response;
                if (err.response.status === 401) {
                    setToken(null);
                } else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                    }
                    else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }
                }
                setLoading(false);
            })
    }

    useEffect(() => {
        getObservations();
    }, []);

    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col  max-h-screen overflow-y-auto bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto md:w-1/2 lg:w-1/2 xl:w-1/2 rounded-xl'>
                        <div className=''>
                            <Header icon={<FaExclamation />} title={student.eleve} />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }
                        <div className="flex w-full justify-end">
                            <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                        </div>
                        <div className='mt-3 w-full overflow-x-auto'>
                            <table className='table-auto'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Par</th>
                                        <th>Observation</th>
                                    </tr>
                                </thead>
                                {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="9"><Loading /></td></tr></tbody> :
                                    (

                                        <tbody>
                                            {observations && observations.map(o => (
                                                <tr className='bg-gray-100' key={o.id}>
                                                    <td>
                                                        <span>{o.dateObservation}</span>
                                                    </td>
                                                    <td>{o.auteur}</td>
                                                    <td>{o.content}</td>
                                                </tr>
                                                ))
                                            } 
                                        </tbody>
                                    )
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EleveObservation